<!-- @format -->
<script lang="ts">
  import type { SwiperOptions } from "swiper";
  import { register } from "swiper/element/bundle";
  import { generateRandomID } from "~/util/common";
  // register Swiper custom elements -- May need to be moved generic place if used by more than one comp on the page
  register();
  export let title: string;

  const id = generateRandomID();

  const params: SwiperOptions = {
    navigation: {
      enabled: true,
      nextEl: `#next-${id}`,
      prevEl: `#prev-${id}`,
    },
    autoplay: {
      delay: 3000,
    },
    pagination: {
      type: "fraction",
      el: `#pagination-${id}`,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 24,
        slidesPerGroup: 3,
        pagination: {
          type: "bullets",
          el: `#pagination-${id}`,
          clickable: true,
          renderBullet: (_index, className) =>
            `<span class="${className}"></span>`,
        },
        autoplay: {
          delay: 5000
        },
      },
    },
  };

  let swiper_el: HTMLElement & {
    initialize: () => void;
  };

  // Reactive expression will always work while onMount might get ran before
  // element is in DOM
  $: if (swiper_el) {
    Object.assign(swiper_el, params);
    swiper_el.initialize();
  }
</script>

<div class="row py-lg-6 py-5">
  <div class="col-12 mb-lg-5 mb-4">
    <div class="header">
      <h2 class="lift-title">{@html title}</h2>
      <slot name="filter" />
    </div>
  </div>
  <div class="col-12">
    <swiper-container
      bind:this={swiper_el}
      init="false"
      class="position-relative">
      <slot name="swiper" />
    </swiper-container>
    <div class="control">
      <div class="control-navigation">
        <div class="navigation-button" id="prev-{id}">
          <i class="fa fa-chevron-left" />
        </div>
        <div class="navigation-button" id="next-{id}">
          <i class="fa fa-chevron-right" />
        </div>
      </div>
      <div class="swiper-pagination ml-md-4" id="pagination-{id}" />
      <div class="d-none d-md-block ml-auto">
        <slot name="link-button" />
      </div>
    </div>
    <div class="d-md-none mt-4">
      <slot name="link-button" class="d-md-none" />
    </div>
  </div>
</div>

<style lang="scss">
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation-button {
    width: 32px;
    height: 32px;
    border: 1px solid #000;
    border-radius: 50%;
    position: unset;
    display: inline-flex;
    margin-right: 16px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }

  .control-navigation :global(.swiper-button-disabled) {
    opacity: 0.4;
  }

  .swiper-pagination {
    position: unset;
    display: flex;
    position: absolute;
    width: fit-content;
    left: 50%;
    justify-content: center;
    top: 0;
    height: 100%;
    align-items: center;
    @media screen and (min-width: 768px) {
      width: fit-content;
      position: unset;
      margin-left: 32px;
    }

    :global(.swiper-pagination-bullet) {
      border: 1px solid #000;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
      color: #000;
      margin-right: 0.7rem;
    }
    :global(.swiper-pagination-bullet-active) {
      color: #000;
      background: #000;
    }
  }
  
  .control {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 768px) {
      justify-content: space-between;
    }
    @media only screen and (min-width: 992px) {
      justify-content: flex-start;
    }
  }
</style>
